import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Make Up Day.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Monday, September 7th, we’ll be having our free Labor Day
workout at 10:30am.  This year it will be a partner workout with both
partners occupying one of our lanes and sharing that lane’s equipment.
 If you and a friend would like to attend please email
Daniel\\@crossfittheville.org to sign up or for more information.  `}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`All other class times are cancelled for Labor Day.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      